* {
  box-sizing: border-box;
}

html {
  -webkit-overflow-scrolling: touch;
  overflow-x: hidden;
  overflow-y: scroll;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  width: 100%;
  height: 100vh;
  font-family: 'Fira Sans', sans-serif;
  line-height: 1.5;
  background-color: #fff;
}
