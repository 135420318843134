.line {
  height: 4px;
}
.promoted {
  text-align: right;
  font-size: 14px;
  padding-right: 20px;
  padding-top: 10px;
  color: #8f8f8f;
}
.promoted > a {
  text-decoration: underline;
  color: #8f8f8f;
}
.surveyContainer {
  max-width: 850px;
  margin: 0 auto;
}
.logoContainer {
  margin: 20px 0;
  text-align: center;
  position: relative;
}

.logo {
  display: inline-block;
}
.textContainer {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
}
.question {
  max-width: 450px;
  font-weight: 700;
}
.scoresContainer {
  margin-top: 20px;
  text-align: center;
}
.score {
  display: inline-block;
  border: 1px #d0d0d0 solid;
  padding: 20px;
  line-height: 0;
  margin: 2px;
  background-color: #fff;
  color: #0A0A0A;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0 2px 0 0 rgba(0,0,0,0.05);
  -webkit-box-shadow: 0 2px 0 0 rgba(0,0,0,0.05);
  box-shadow: 0 2px 0 0 rgba(0,0,0,0.05);
}

.questionAndScores {
  padding: 15px;
  max-width: 650px;
  border: 1px #ddd solid;
  background-color: #fcfcfc;
  margin: 20px auto 0;
}

a {
  color: #000;
  text-decoration: none;
}

@media(max-width: 750px) {
  .score {
    display: block;
    margin-top: 10px;
  }
}
